// Converted Variables
// Custom Media Query Variables

$gap: 24px;

@function getGaps($gaps) {
  @return $gaps * 24px;
}

@mixin calc-width-s($columns) {
  $grid-columns: 4;
  $gaps: getGaps(3);
  $page-margin: 56px;
  $grid-columns-width: calc((100vw - #{$page-margin}) - #{$gaps});
  $gaps-in-between: calc((#{$columns} - 1) * #{$gap});
  width: calc(
    ((#{$grid-columns-width}) / #{$grid-columns}) * #{$columns} + #{$gaps-in-between}
  );
}

@mixin calc-width-m($columns) {
  $grid-columns: 12;
  $gaps: getGaps(11);
  $page-margin: 72px;
  $grid-columns-width: calc((100vw - #{$page-margin}) - #{$gaps});
  $gaps-in-between: calc((#{$columns} - 1) * #{$gap});
  width: calc(
    ((#{$grid-columns-width}) / #{$grid-columns}) * #{$columns} + #{$gaps-in-between}
  );
}

@function getMaxColWidth($columns, $gaps-in-between) {
  $max-col-width: calc((936px - (11 * 24px)) / 12);
  $total: calc(#{$max-col-width} * #{$columns} + #{$gaps-in-between});
  @return #{$total};
}

@mixin calc-width-l($columns) {
  $grid-columns: 12;
  $gaps: getGaps(11);
  $page-margin: 88px;
  $grid-columns-width: calc((100vw - #{$page-margin}) - #{$gaps});
  $gaps-in-between: calc((#{$columns} - 1) * #{$gap});
  width: calc(
    ((#{$grid-columns-width}) / #{$grid-columns}) * #{$columns} + #{$gaps-in-between}
  );
  max-width: getMaxColWidth(#{$columns}, #{$gaps-in-between});
}

// GRID
// mobile 4 column grid

@media #{$media-s}, #{$media-xs} {
  .col-1-s {
    @include calc-width-s(1);
  }

  .col-2-s {
    @include calc-width-s(2);
  }

  .col-3-s {
    @include calc-width-s(3);
  }

  .col-4-s {
    width: 100%;
  }
}

// tablet 12 column grid

@media #{$media-m} {
  .col-1-m {
    @include calc-width-m(1);
  }

  .col-2-m {
    @include calc-width-m(2);
  }

  .col-3-m {
    @include calc-width-m(3);
  }

  .col-4-m {
    @include calc-width-m(4);
  }

  .col-5-m {
    @include calc-width-m(5);
  }

  .col-6-m {
    @include calc-width-m(6);
  }

  .col-7-m {
    @include calc-width-m(7);
  }

  .col-8-m {
    @include calc-width-m(8);
  }

  .col-9-m {
    @include calc-width-m(9);
  }

  .col-10-m {
    @include calc-width-m(10);
  }

  .col-11-m {
    @include calc-width-m(11);
  }

  .col-12-m {
    width: 100%;
  }
}

// desktop 12 column grid

@media #{$breakpoint-large} {
  .col-1-l {
    @include calc-width-l(1);
  }

  .col-2-l {
    @include calc-width-l(2);
  }

  .col-3-l {
    @include calc-width-l(3);
  }

  .col-4-l {
    @include calc-width-l(4);
  }

  .col-5-l {
    @include calc-width-l(5);
  }

  .col-6-l {
    @include calc-width-l(6);
  }

  .col-7-l {
    @include calc-width-l(7);
  }

  .col-8-l {
    @include calc-width-l(8);
  }

  .col-9-l {
    @include calc-width-l(9);
  }

  .col-10-l {
    @include calc-width-l(10);
  }

  .col-11-l {
    @include calc-width-l(11);
  }

  .col-12-l {
    width: 100%;
  }
}

// Utilities

@media #{$breakpoint-not-small} {
  .one-gap-ns {
    margin-right: $gap;
  }
  .grid-gap-reset-ns {
    margin-left: 0;
    margin-right: 0;
  }
  .grid-left-gap-reset-ns {
    margin-left: 0;
  }
}
