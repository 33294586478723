@import 'variables'; // CL Variables

.stretch-img-h img {
  width: 100%;
}

.background-img-cover {
  overflow: hidden;
}

.background-img-cover img {
  width: 100%;
  height: 100%;
  max-width: none !important;
  max-height: none !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.smaller-bubble jb-image,
.smaller-bubble jb-image img {
  width: $jb-4;
}

@media #{$breakpoint-not-small} {
  .smaller-bubble jb-image,
  .smaller-bubble jb-image img {
    width: $jb-6;
  }

  .large-img img {
    max-width: 17.5rem !important;
  }
}

.placeholder-one-third .stretch-placeholder {
  height: 33vh !important;
}
