// Converted Variables

// Custom Media Query Variables

/* You can add global styles to this file, and also import other style files */
@import 'jb-component-library/styles/index.css';

// Okta styles
@import '@okta/okta-signin-widget/css/okta-sign-in.min.css';

// Order of these importants is IMPORTANT, changing can cause build failures.
@import 'variables';
@import 'utils';
@import 'global';
@import 'animations';
@import 'grid';
@import 'jb-image';
@import 'z-index';
@import 'asapp';

// Setting display none to images that are direct children of <body>
// This is to address a tracking pixel bug in DOT-10278
body > img {
  display: none;
}

.sr-only {
  border: 0;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  float: left;
}

// TODO remove when CL issue in 22.1.0, DOT-9410
jb-dialog-content {
  overflow: unset !important; // need important cause CL styles after these styles
}

// TODO remove with next CL update
/* Fix Safari 16.4 label position */
jb-form-field-container > div > span > jb-input-label {
  display: block;
}

.image-container {
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-bottom: var(--aspect-ratio);
  }

  jb-image,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
  }
}
