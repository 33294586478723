@import 'variables';

// Custom Media Query Variables
/* CL overrides until IOS15 changes are implemented */

.negative-page-margin {
  margin-left: min(0rem - (env(safe-area-inset-left)), $page-margin-s * -1);
  margin-right: min(0rem - (env(safe-area-inset-right)), $page-margin-s * -1);
}

@media #{$media-xs}, #{$media-s} {
  .negative-page-margin-s {
    margin-left: min(0rem - (env(safe-area-inset-left)), $page-margin-s * -1);
    margin-right: min(0rem - (env(safe-area-inset-right)), $page-margin-s * -1);
  }
}

@media #{$media-m} {
  .negative-page-margin,
  .negative-page-margin-m {
    margin-left: min(0rem - (env(safe-area-inset-left)), $page-margin-m * -1);
    margin-right: min(0rem - (env(safe-area-inset-right)), $page-margin-m * -1);
  }
}

@media #{$media-l} {
  .negative-page-margin,
  .negative-page-margin-l {
    margin-left: min(0rem - (env(safe-area-inset-right)), $page-margin-l * -1);
    margin-right: min(0rem - (env(safe-area-inset-right)), $page-margin-l * -1);
  }
}
/* Page margins to be used across all JetBlue apps. */
.page-margin {
  padding-left: max(env(safe-area-inset-left), $page-margin-s);
  padding-right: max(env(safe-area-inset-right), $page-margin-s);
}

@media #{$media-xs}, #{$media-s} {
  .page-margin-s {
    padding-left: max(env(safe-area-inset-left), $page-margin-s);
    padding-right: max(env(safe-area-inset-right), $page-margin-s);
  }
}

@media #{$media-m} {
  .page-margin,
  .page-margin-m,
  .page-margin-ns {
    padding-left: max(env(safe-area-inset-left), $page-margin-m);
    padding-right: max(env(safe-area-inset-right), $page-margin-m);
  }
}

@media #{$media-l} {
  .page-margin,
  .page-margin-l,
  .page-margin-ns {
    padding-left: max(env(safe-area-inset-left), $page-margin-l);
    padding-right: max(env(safe-area-inset-right), $page-margin-l);
  }
}

/* To resolve DOT-8264 of content not being aligned with first grid column due
to .jb-max-width constraining width */
@media (max-width: 1023px) {
  .jb-max-width {
    max-width: none;
  }
}

/* CL OVERRIDES END */

// Mobile Classes
@media #{$media-xs}, #{$media-s} {
  .justify-center-s {
    justify-content: space-between;
  }

  .flex-column-s {
    flex-direction: column;
  }

  .page-margin-s {
    padding-left: max(env(safe-area-inset-left), 1.75rem);
    padding-right: max(env(safe-area-inset-right), 1.75rem);
  }

  .hide-mobile {
    display: none !important;
  }
}

jb-radio-group li:last-child jb-radio {
  /* stylelint-disable length-zero-no-unit */
  margin-right: 0;
  /* stylelint-enable */
}

jb-booker .multicity {
  margin-left: $spacing-small !important;
}

button {
  appearance: none;
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: inherit;
  overflow: visible;
  outline: none;
}

input {
  padding: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.spin {
  animation: spin 1s infinite linear;
}

/* Remove these and replace the classes in the html with the latest guide */
@media #{$breakpoint-not-small} {
  .ph6-temp-ns {
    padding-left: $jb-8;
    padding-right: $jb-8;
  }
}

.ml6-temp {
  padding-left: $jb-8;
}

@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
    -webkit-tap-highlight-color: $white;
  }
}

@media #{$breakpoint-not-small} {
  .copy-ns {
    font-size: $font-size-5;
    line-height: $spacing-large;
    letter-spacing: 0;
  }

  .copy-s-ns {
    font-size: $font-size-6;
    line-height: 1.25rem;
    letter-spacing: 0;
  }
}

// JbGrid adjustments
@for $i from 1 through 12 {
  @media #{$breakpoint-medium} {
    .col-#{$i}-m-temp {
      @include create-col($i);
    }
  }
}

@for $i from 1 through 12 {
  @media #{$breakpoint-medium} {
    .col-#{$i}-m-left-temp {
      @include create-col($i);
    }
  }
}

@for $i from 1 through 12 {
  @media #{$breakpoint-medium} {
    .col-#{$i}-m-right-temp {
      @include create-col($i);
    }
  }
}

// for right-most third of list component
@for $i from 1 through 12 {
  @media #{$breakpoint-medium} {
    .col-#{$i}-m-right-third-temp {
      @include create-col($i);
    }
  }
}

// for body text w/ image & list component
.col-4-m-left-temp {
  @media #{$breakpoint-large} {
    @include create-col(3);
    @include ml-offset(1);
    @supports (display: grid) {
      grid-column: 2 / 5;
    }
  }
}

// for body text w/ image
.col-4-m-right-temp {
  @media #{$breakpoint-large} {
    @include create-col(3);
    @include mr-offset(1);
    @supports (display: grid) {
      grid-column: 9 / 12;
    }
  }
}

// for right-most third of list component
.col-4-m-right-third-temp {
  @media #{$breakpoint-large} {
    @include create-col(3);
    @include mr-offset(1);
    @supports (display: grid) {
      grid-column: 8 / 11;
    }
  }
}

// for body text w/ image
.col-8-m-left-temp {
  @media #{$breakpoint-large} {
    @include create-col(7);
    @include ml-offset(1);
    @supports (display: grid) {
      grid-column: 2 / 9;
    }
  }
}

// for body text w/ image
.col-8-m-right-temp {
  @media #{$breakpoint-large} {
    @include create-col(7);
    @include mr-offset(1);
    @supports (display: grid) {
      grid-column: 5 / 12;
    }
  }
}

// this class is centered for list component
.col-4-m-temp {
  @media #{$breakpoint-large} {
    @include create-col(3);
    @supports (display: grid) {
      grid-column: 5 / 8;
    }
  }
}

// for contextual help component
.col-8-m-temp {
  @media #{$breakpoint-not-small} {
    @include create-col(8);
    @include mr-offset(1);
    @include ml-offset(1);
    @supports (display: grid) {
      grid-column: 3 / 11;
    }
  }
  @media #{$breakpoint-large} {
    @include create-col(6);
    @include mr-offset(1);
    @include ml-offset(1);
    @supports (display: grid) {
      grid-column: 4 / 10;
    }
  }
}

// for section header
.col-10-m-temp {
  @media #{$breakpoint-large} {
    @include create-col(8);
    @include mr-offset(1);
    @include ml-offset(1);
    @supports (display: grid) {
      grid-column: 3 / 11;
    }
  }
}

.mw-override {
  max-width: none;
}

.wb {
  word-break: break-word;
}

.h-auto {
  height: auto;
}

// TO-DO: Remove this class when standarizing CL inputs
.input-height-override {
  height: 56px;
}

.screen-reader-only {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 4px !important;
  height: 4px !important;
  opacity: 0 !important;
  overflow: hidden !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  display: block !important;
  visibility: visible !important;
}

// grid/flexbox gaps
.cg1 {
  column-gap: $spacing-extra-small;
}
.cg2 {
  column-gap: $spacing-small;
}
.cg3 {
  column-gap: $spacing-medium;
}
.cg4 {
  column-gap: $spacing-large;
}
.cg5 {
  column-gap: $spacing-extra-large;
}
.rg1 {
  row-gap: $spacing-extra-small;
}
.rg2 {
  row-gap: $spacing-small;
}
.rg3 {
  row-gap: $spacing-medium;
}
.rg4 {
  row-gap: $spacing-large;
}
.rg5 {
  row-gap: $spacing-extra-large;
}

// Flexbox workaround for iOS 14 and lower
@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .cg1 > *:not(:last-child) {
    margin-right: $spacing-extra-small;
  }
  .cg2 > *:not(:last-child) {
    margin-right: $spacing-small;
  }
  .cg3 > *:not(:last-child) {
    margin-right: $spacing-medium;
  }
  .cg4 > *:not(:last-child) {
    margin-right: $spacing-large;
  }
  .cg5 > *:not(:last-child) {
    margin-right: $spacing-extra-large;
  }
  .rg1 > *:not(:last-child) {
    margin-bottom: $spacing-extra-small;
  }
  .rg2 > *:not(:last-child) {
    margin-bottom: $spacing-small;
  }
  .rg3 > *:not(:last-child) {
    margin-bottom: $spacing-medium;
  }
  .rg4 > *:not(:last-child) {
    margin-bottom: $spacing-large;
  }
  .rg5 > *:not(:last-child) {
    margin-bottom: $spacing-extra-large;
  }
}

.disable-scroll {
  touch-action: none;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
  left: 0;
  position: fixed;
  right: 0;
  overflow-y: hidden !important;
}
