/* ASAPP Widget Style Overrides */

.asappChatSDKBadge {
  z-index: $zIndex-7 !important;
}
.asappChatSDKIFrame {
  z-index: $zIndex-9 !important;
}

:root {
  --jb-show-asapp-chat: none;
}

.asappChatSDKBadge {
  display: var(--jb-show-asapp-chat);
}
