// Converted Variables

// Custom Media Query Variables

.zn-1 {
  z-index: -1;
}

.right-divider {
  border-right-style: solid;
  border-right-color: $medium-gray;
  border-right-width: 0;
  /* specify border-width when using it */
}

.left-divider {
  border-left-style: solid;
  border-left-color: $medium-gray;
  border-left-width: 0;
  /* specify border-width when using it */
}

.bottom-divider {
  border-bottom-style: solid;
  border-bottom-color: $medium-gray;
  border-bottom-width: 0;
  /* specify border-width when using it */
}

.bottom-divider {
  border-bottom-style: solid;
  border-bottom-color: $medium-gray;
  border-bottom-width: 0;
}

.no-bottom-divider {
  border-bottom-style: solid;
  border-bottom-color: transparent;
  border-bottom-width: 0;
}

.border-color-royal-blue {
  border-color: $royal-blue;
}

.border-color-medium-gray {
  border-color: $medium-gray;
}

.border-color-light-gray {
  border-color: $light-gray;
}

.clip {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.mobile-header-top {
  padding-top: $header-height;
}

@media #{$breakpoint-not-small} {
  .mobile-header-top {
    padding-top: inherit;
  }

  // TODO: Identify components affected by this rule
  // promo-block (DOT-7431)
  /* tachyons bug */
  .ma0-ns {
    margin: 0 !important;
  }
}

.menu-shadow {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.skip-to-content {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.skip-to-content:focus,
.skip-to-content:active {
  left: auto;
  top: auto;
  height: auto;
  width: auto;
  overflow: auto;
  z-index: 2147483647;
  text-decoration: underline;
}

.circle {
  height: 6em;
  width: 6em;
}

.hr-underline {
  height: 0.25rem;
  background: $orange;
  border: none;
  transition: 0.15s ease-in-out;
  margin: 0 auto;
  right: 0;
  left: 0;
}

.dialog .dialog-dn {
  display: none;
}

.dialog .dialog-no-bottom {
  height: 90%;
}

@media #{$media-s}, #{$media-xs} {
  .dialog .dialog-no-bottom {
    height: 100%;
  }

  .clip-s {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

/* Top */
.top-50 {
  top: 50%;
}

.top-40 {
  top: 40%;
}

.top-100 {
  top: 100%;
}

.flex-imp {
  /* stylelint-disable value-no-vendor-prefix */
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  /* stylelint-enable */
  display: flex !important;
}

@media #{$breakpoint-not-small} {
  .top-100-ns {
    top: 100%;
  }
}

/* Inline error styles */
.error-state {
  border-color: $red;
}

.invalid-control,
.error-messages {
  color: $red;
}

.w-100-important {
  width: 100%;
}
