/* The Z-Index Scale is internal and should NOT be changed or directly applied
to components. Add a z-index to a component by adding the component to the
appropriate grouping in the Z-Index Applications section and prefixing the new
class with DOT. */

// Z-Index Scale (private vars)
// --------------------------------------------------
$zIndex-negative-10: -10;
$zIndex-0: 0;
$zIndex-1: 10;
$zIndex-2: 20;
$zIndex-3: 30;
$zIndex-4: 40;
$zIndex-5: 50;
$zIndex-6: 60;
$zIndex-7: 70;
$zIndex-8: 80;
$zIndex-9: 90;
$zIndex-10: 100;
$zIndex-999: 999;

// Z-Index Applications
// --------------------------------------------------
$zIndex-negative-10--hiddenElement: $zIndex-negative-10;

$zIndex-0--screenBackground: $zIndex-0;

$zIndex-3--blockLinkOverlay: $zIndex-3;

$zIndex-5--buttonLink: $zIndex-5;

$zIndex-6--overlay: $zIndex-6;

$zIndex-7--stickyNav: $zIndex-7;

// $zIndex-8--panel:                   $zIndex-8;
$zIndex-9--loader: $zIndex-9;

// $zIndex-10--dev:                    $zIndex-10;

$zIndex-999--header: $zIndex-999;

// Z-index Shareable Classes
// --------------------------------------------------

.dot-header-z9 {
  z-index: 85; // Needs to be lower than the alert and global nav (86) but higher than TB dashboard nav (80)
}

.dot-header-alert-z10 {
  z-index: 86; // Needs to be higher than TB dashboard nav (80) and higher than header (85)
}

.dot-loader-z9 {
  z-index: $zIndex-9--loader;
}

@media #{$media-xs}, #{$media-s} {
  .dot-overlay-z6-s {
    z-index: $zIndex-6--overlay;
  }
}
