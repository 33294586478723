@import 'variables'; // CL Variables

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.spin {
  animation: spin $td-slow infinite linear;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.slideDown {
  animation: slideDown $td-fast ease-in;
  animation-fill-mode: forwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

.slideUp {
  animation: slideUp 0.15s ease-out;
  animation-fill-mode: forwards;
}

@keyframes shine-lines {
  0% {
    background-position: 100%;
  }
  50%,
  100% {
    background-position: -100%;
  }
}
